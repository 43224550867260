import { graphql } from 'gatsby';
import React from 'react';
import { InhouseDayProps } from '../../utils/strapiTypes';
import { Col, Row } from 'antd';
import { useMicrocopy } from '../hooks';
import { InhouseDay, Layout } from '../components';

interface QueryResult {
  strapi: {
    inhouseDays: [InhouseDayProps],
  },
};

export const query = graphql`
  query {
    strapi {
      inhouseDays {
        id
        slug
        title
        startDate
        endDate
        time
        location
        description
        company {
          name
          logo {
            url
            alternativeText
          }
        }
        finalSignupDate
        image {
          url
          alternativeText
        }
      }
    }
  }
`;

const InhouseDagenPage = ({ data }: { data: QueryResult }) => {
  const { inhouseDays } = data.strapi;
  const m = useMicrocopy();

  const sortedDays = inhouseDays
    .map(day => ({
      ...day,
      sortDate: new Date(day.startDate),
    }))
    .sort((a, b) => a.sortDate - b.sortDate);

  return <Layout>
    <div className="container-sm">
      <h2 style={{ marginTop: 30 }}>{m('InhouseDayTitle')}</h2>
      <p>{m('InhouseDayDescription')}</p>
      <Row gutter={[32, 32]}>
        {sortedDays.map(day => {
          return <Col key={day.id} md={12} lg={8}>
            <InhouseDay
              title={day?.title}
              startDate={day?.startDate}
              endDate={day?.endDate}
              time={day?.time}
              location={day?.location}
              finalSignupDate={day?.finalSignupDate}
              company={day?.company}
              slug={day.slug}
              id={day.id}
            />
          </Col>
        })}
      </Row>
    </div>
  </Layout>
};

export default InhouseDagenPage;
